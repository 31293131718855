.gallery-menu {
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #f5d3a8;
    margin-bottom: 10px;
}

.gallery-menu .title {
    font-weight: bold;
}

.gallery-menu .menu-link {
    margin: 0 4px 4px 0;
}

.gallery-menu.inline .menu-link {
    display: inline-block;
}

.gallery-menu .menu-link a {
    color: black;
}

.gallery-menu .menu-link a .btn-sm {
    padding: 0 6px;
    font-size: 14px;
}