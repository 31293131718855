.copyright-note {
    padding: 5px;
    margin-top: 10px;
    border-top: 1px #c4b5ab solid;
    text-align: center;
    font-size: 12px;
}

.typography {
    text-align: center;
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
    margin-top: 20px;
    color: #b6af93;
    text-align: center;
}

.typography p {
    text-align: center;
}