.top-menu {
    border-bottom: 1px #c4b5ab solid;
    margin-bottom: 5px;
}

.top-menu .logo-row .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-menu .logo-row .logo div {
    position: relative;
    font-family: serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: white;
    text-shadow: black 0 0 2px, black 0 0 2px, black 0 0 2px, black 0 0 2px;
    text-align: center;
}

.top-menu .logo-row .logo div:nth-child(2) {
    top: -30px;
}

.top-menu .logo-row .logo div:nth-child(3) {
    top: -20px;
}

.top-menu .socials-row svg {
    font-size: 24px;
    padding: 10px;
}

.top-menu .links-row .link {
    display: inline-block;
    padding: 10px 20px 5px 20px;
    border-bottom: #d9d9d9 3px solid;
    margin: 5px;
    text-transform: capitalize;
    font-size: 14px;
    border-radius: 15px 15px 5px 5px;
    color: black;
}

.top-menu .links-row .link.active {
    background-color: #f5d3a8;
}

.top-menu .links-row .link:hover {
    border-color: #d2af7e;
    cursor: pointer;
}