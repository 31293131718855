.gallery a {
    text-decoration: none;
}

.gallery .grid .gallery-image {
    border-radius: 15px 15px 15px 15px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.25);
    text-align: center;
}

.gallery .grid .gallery-image .footer {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
}

.gallery .grid .gallery-image .footer .title {
    font-size: 14px;
}

.gallery .grid .gallery-image .footer .year {
    font-size: 10px;
}